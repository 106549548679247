.card {
  display: grid;
  width: 100%;
  height: 338px;
  border: 1px solid #bdbdbd;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  margin: auto;
  box-shadow: 2px 3px 10px 0px black;
  grid-template-columns: 1fr;
  grid-template-rows: 288px 28px 22px;
  grid-template-areas: "photo" "info-card" "empty";
}
    
.card:visited {
  color: black;}
    
.card:active {
  color: black;}
    
.card:hover {
  color: black;}

.imagenCard {
  width: 288px;
  height: 288px; 
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  grid-area: photo;
}

.infoCardContainer{
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 90px;
  height: fit-content;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  grid-area: info-card;
}

.tituloCard {
  width: 100%;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 25px;
  max-height: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 1; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; 
  word-wrap:break-word;   
  padding-left: 15px;
  margin: auto;    
}

.precioContainer{
  width: 80px;
  height: 28px;
  border: 1px solid black;
  border-radius: 20px;
  padding-right: 13px; 
}
  
.precioCard {
  position: relative;
  font-size: 15px;
  font-family: Poppins;
  font-weight: bold; 
  text-align: center; 
  top: 3px;
  left: 7px;  
  margin: auto;   
}