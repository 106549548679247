.itemMobile {
  display: grid;
  width: 100%;
  margin-top: 7px;
  flex-wrap: wrap;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  justify-content: center;
  grid-template-columns: 135px 1fr 70px;
  grid-template-rows: 45px 45px 45px;
  grid-template-areas:  "imagenCartItem tituloCartItem moreQuantity" 
                        "imagenCartItem priceCartItem quantityCartItem" 
                        "imagenCartItem removeItemIcon minusQuantity";
  border: 1px solid #6c6c6c;
  border-radius: 10px;
  text-align: center;
  font-size: 16px; }

.imagenCartItem {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  grid-area: imagenCartItem;
  margin: 0 auto;
  width: 135px; }

.tituloCartItem{
  display: flex;
  align-items: center;
  width: 100%;  
  height: 30px;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 700;
  text-align: left;
  line-height: 30px;  
  display: -webkit-box;
  -webkit-line-clamp: 1; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; 
  word-wrap:break-word;     
  grid-area: tituloCartItem;
}

.priceCartItem{
  position: relative;
  top: 2px;
  text-align: left;  
  font-size: 19px;
  font-weight: 700;
  grid-area: priceCartItem;
}

.quantityCartItem {
  grid-area: quantityCartItem;
}

.moreQuantityIcon {
  grid-area: moreQuantity;
  cursor: pointer;
  margin: 0 auto;
  width: 27px;
  height: 27px; }
  
.lessQuantityIcon {
  grid-area: minusQuantity;
  cursor: pointer;
  margin: 0 auto;
  width: 27px;
  height: 27px; 
}  

.removeItemCartContainer{
  display: flex;  
  position: relative;
  top: 4px; 
  cursor: pointer; 
  grid-area: removeItemIcon;
}
  
.removeIcon{
  position: relative;
  top: 2px;
  left: 0;
  width: 17px;
  height: 17px;
}
  
.removeText { 
  position: relative;
  top: 0;
  left: 2px;   
  font-size: 15px;
    
}