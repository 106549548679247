.tituloNosotrosPortada {
  grid-area: tituloNosotrosPortada;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 30px;
  text-align: center;
}

@media screen and (min-width: 250px){
  .tituloNosotrosPortada {
    margin: 20px auto;
  }
}