.ItemDetailsContainer{
  padding: 56px 20px 50px 20px;
  min-height: calc(100vh - 60px);
  width: 100%;
}

@media screen and (min-width: 1050px) {
  .ItemDetailsContainer {    
    padding: 56px 20px 50px 20px; 
    min-height: calc(100vh - 60px); 
  }  
}  