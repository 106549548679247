header{
  display: flex;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 56px;
  z-index: 2;
  background-color: white;   
}

.contenedorMenu{
  display:flex;
  align-items: center;
  height: 100%;
  width: 100%;   
  z-index: 1;  
}

.menuExpandible{    
  background-color: rgb(255, 255, 255); 
  z-index: 100;  
}

.toggleButton:focus{
  box-shadow: none;
}

.link{
  display: flex;
  height:100%;
  width: fit-content;
  align-items: center;
  margin: 0 15px;   
}

.logoLink{
  margin-left: 0;
}

.logo{
  margin: 0;
}

.carro{
  display:flex;
  position: relative;
  top: -2px;
  width: 35px;
  height: 35px;
}

.quantityLink{
  position: absolute;
  top: 30px;
  right: 38px;
  color: rgb(245, 153, 33);    
}

.desplegableMenuItem{    
  white-space:nowrap;
  height: 24px;
  width: fit-content;
}

.carroDesktop{
  display: none;
  position: relative;
  top: -2px;
  width: 35px;
  height: 35px;
}

.quantityIcon{
  width:22px;
  height:22px;
}

.quantityIconplus{
  position: absolute;
  top: 8px;
  right: 24px;
  color: rgb(73, 73, 73);
  width:11px;
  height:11px;
}

@media (min-width: 992px) {
	.carro{
		display: none;
	}
			
	.carroDesktop{
		display: flex;
	}    
}