.mainNosotros {  
  margin: 0 auto;
  padding: 76px 20px 50px 20px;
  width: 100%;
  max-width: 1000px;
  height: fit-content;
  min-height: calc(100vh - 160px); 
}


.aboutUsTitle{
  text-align: center;
}

.subTitle1{
  margin-top: 30px;
}

.aboutUsP2, .aboutUsP3, .aboutUsP5, .aboutUsP6{
  margin-top: 15px;
}

.subTitle2{
  margin-top: 40px;
}

@media screen and (min-width: 1050px) {
  .mainNosotros {     
    min-height: calc(100vh - 60px); 
  }  
}   
