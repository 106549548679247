.paymentButtonsContainer{
  margin: 15px auto 0 auto;
  width: 100%;    
  max-width: 320px;
  height: fit-content;
}

.paymentsOptionsMessages{
  font-size: 17px;
  margin-bottom: 3px;
}

.paymentOptionsButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  width: 100%;
  height: 45px;
  border: none;
  border-radius: 6px;
  font-size: 15px;
  color: white;
  background-color: black;
  font-weight: 600;
  font-family: montserrat, sans-serif, Arial, Helvetica, sans-serif;
  box-shadow: 0 0 0 #fff;
}

.paymentOptionsButton:hover {
  color: white;
}

.mercadopagoicono {
  margin-right: 15px;
  width: 50px;
}

.MPButton {    
  margin-bottom: 25px;
  background-color: #009EE3;
}

.customOrderButton{
  background-color: black;    
}