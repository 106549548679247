*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
}

button, a{
  cursor: pointer;
}
a{
  color: black;
  text-decoration: none;
}

a:active{
  color: black;
}

a:hover{
  color: black;
}

h1, h2, h3, h4, h5,h6, p, a , span, button{
  font-family: Poppins, sans-serif;
}

.spinnerContainer{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  top: calc(50vh - 35px);
  right: calc(50vw - 35px);
}


