.totalCartContainer {
  display: grid;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
  margin-top: 7px;
  grid-template-columns: 1fr 120px 100px 120px;
  grid-template-rows: 50px;
  grid-template-areas: ". totalCartTitle totalCartNumber emptyCart"; 
  border-radius: 10px;
	border: 1px solid black;
  background-color: #F6F6F6;
}

.totalCartContainerMobile {
  display: grid;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 7px auto 0 auto;
  grid-template-columns: 130px auto 1fr 120px;
  grid-template-rows: 50px;
  grid-template-areas: "totalCartTitle totalCartNumber . emptyCart"; 
	border-radius: 10px;
	border: 1px solid black;
  background-color: #F6F6F6;
}
      
.totalCartTitle {
  display: flex;
  justify-content: center;
  font-size: 20px;    
  font-weight: 700;
  margin-right: 0; 
  grid-area: totalCartTitle;
}
      
.totalCartNumber {
  grid-area: totalCartNumber;
  font-weight: 700;
  font-size: 20px; 
}

.emptyCartButton{
  border: none;
  display: flex;
  margin-left: 0;
  width: fit-content;
	height: 100%;
  align-items: center;
  justify-content: space-around;
  background-color: #F6F6F6;
	border-bottom-right-radius: 10px;
	border-top-right-radius: 10px;
	grid-area: emptyCart;
}

@media screen and (min-width: 768px) {
  .totalCartTitle {    
    justify-content: right;    
  }
}  