.itemDesktop {
  display: grid;
  width: 100%;
  margin-top: 7px;
  height: fit-content;
  align-items: center;
  justify-content: center;
  grid-template-columns: 140px 1fr 100px 40px 40px 40px 100px 120px;
  grid-template-rows: 140;
  grid-template-areas: "imagenCartItem tituloCartItem priceCartItem minusQuantity quantityCartItem moreQuantity subTotalCartItem removeItemIcon";
  border: 1px solid black;
  border-radius: 10px;
  text-align: center;
  font-size: 16px; }

.imagenCartItem {
  grid-area: imagenCartItem;
  margin: 0 auto;
  width: 140px; }
  
.tituloCartItem {
  text-align: center;
  height: 24px;
  width: 100%;
  overflow: hidden; }
  
.tituloCartItem {
  grid-area: tituloCartItem; }  

.priceCartItemDesktop {
  margin: auto;
  grid-area: priceCartItem; }
  
.quantityCartItem {
  grid-area: quantityCartItem; }
  
.moreQuantityIcon {
  grid-area: moreQuantity;
  cursor: pointer;
  margin: 0 auto;
  width: 14px;
  height: 14px; }
  
.lessQuantityIcon {
  grid-area: minusQuantity;
  cursor: pointer;
  margin: 0 auto;
  width: 14px;
  height: 14px; }
  
.subTotalCartItem {
  grid-area: subTotalCartItem;
  font-size: 20px; }
  
.removeIcon {
  display: flex;
  width: 25px;
  height: 25px;
  justify-content: right;
  grid-area: removeItemIcon;
  cursor: pointer; }