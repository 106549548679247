.contactUsmainContainer{
  padding-top: 76px;
  min-height: calc(100vh - 160px);
  height: fit-content;
  width: 100%;     
}

.tituloContacto{
  display: flex;
  justify-content: center;    
}

.contactInfoGrid{
  display: grid;
  width: fit-content;
  height: fit-content;
  margin: 20px auto 0 auto;
  grid-template-rows: 270px 2px 270px 2px 270px;
  grid-template-columns: 1fr;
}

.lineContactUs{
  margin: 0 auto;
  width: 80%;
  height: 1px;
  border: 1px solid black;
}

.byPhoneContainer{
  display: grid;
  width: 280px;
  grid-template-rows: 40px 50px 45px 45px;
  margin: 30px auto 30px;    
}

.phoneIcon{
  width: 30px;
  height: 30px;
  margin: auto;
}

.byPhoneTitle{
  margin: auto;
  font-family: Poppins;
  text-align: center; 
}

.contactTimeRange{
  margin: auto;
  font-family: Poppins; 
  text-align: center; 
}

.phoneNumber{
  margin: auto;
  font-weight: bold;
  font-family: Poppins; 
  text-align: center; 
  user-select: text;  
  cursor: text;     
}

.phoneNumber::selection{
  color: white;
  background-color: black;
}

.byMailContainer{
  display: grid;
  width: 280px;
  grid-template-rows: 40px 50px 45px 45px;
  margin: 30px auto 30px;
}

.mailIcon{
  width: 30px;
  height: 30px;
  margin: auto;
}

.byMailTitle{
  margin: auto;
  font-family: Poppins; 
  text-align: center; 
}

.byMailMessage{
  margin: auto;
  font-family: Poppins;
  text-align: center;  
}

.mail{
  position: relative;
  margin: auto;
  font-weight: bold;
  font-family: Poppins; 
  text-align: center; 
  user-select: text; 
  cursor: text;    
}

.mail::selection{
  color: white;
  background-color: black;
}

.copyIcon{
  position: absolute;
  top: 2px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.byChatContainer{
  display: grid;
  width: 280px;
  grid-template-rows: 40px 50px 30px 60px;
  margin: 30px auto 30px;
}

.chatIcon{
  width: 33px;
  height: 33px;
  margin: auto;
}

.byChatTitle{
  margin: auto;
  font-family: Poppins; 
  text-align: center; 
}

.byChatMessage{
  margin: auto;
  font-family: Poppins;
  text-align: center;
}

.chatButton{
  display: flex;
  position: relative;
  width: 200px;
  height: 42px;
  padding-left: 35px;
  align-items: center;
  margin: auto;
  border: 1px solid #4c4c4c;
  border-radius: 10px;
  font-family: Poppins;     
}

.chatButtonIcon{
  position: absolute;
  width: 35px;
  height: 35px;
  bottom: 4px;
  right: 25px;
}

@media screen and (min-width: 1050px) {
  .contactUsmainContainer{       
    min-height: calc(100vh - 60px);        
  }

  .lineContactUs{
    margin: auto;
    width: 2px;
    height: 170px;
    border: 1px solid black;
  }

  .contactInfoGrid{
    display: grid;
    width: fit-content;
    height: fit-content;
    margin: 50px auto 0 auto;
    grid-template-rows: 1fr;
    grid-template-columns: 330px 2px 330px 2px 330px;
  }
}  