.customOrderItem{
  display: grid;
  margin-top: -1px;
  text-align: center;
  height: 102px;
  width: 100%;
  grid-template-columns: 100px 1fr 1fr 1fr;
  grid-template-rows: 25px 25px 25px 25px;
  grid-template-areas: "image name name name"
                        "image name name name"
                        "image quantityTitle priceTitle subTotalTitle "
                        "image quantity price subTotal ";
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.customOrderQuantityTitle{
  grid-area: quantityTitle;
}

.orderCustomSpan{
  display: flex;
  align-items: center;
  justify-content: center;
}
                
.customOrderPriceTitle{
  grid-area: priceTitle;
}

.customOrderSubTotalTitle{
  grid-area: subTotalTitle;
}

.customOrderImage{
  width: 100%;
  grid-area: image;
  border-right: 1px solid black;
}

.customOrderName{    
  border-bottom: 1px solid black;
  grid-area: name;
}

.customOrderQuantity{
  grid-area: quantity;
}

.customOrderPrice{
  grid-area: price;
}

.customOrderSubTotal{
  grid-area: subTotal;
}