.loginContainer{    
  width: 100%;
  max-width: 620px;
  height: fit-content;
  margin: 35px auto 0 auto;    
}
  
.form-container{
  display: flex;    
  width: 100%;
  height: 405px;
  padding: 40px;
  background: white;
  border: 1px solid #6c6c6c;
  border-radius: 12px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.199);  
}

form{
  width: 100%;
  height: 100%;
  border: none;
}

  
.subtitle {
  display: block;
  font-size: 11px;
  color: black;
  margin-bottom: 5px;
}
  
input {
  display: block;
  border: none;
  border-bottom: solid rgb(143, 143, 143) 1px;
  background: none;
  color: black;
  height: 35px;
  width: calc(100% - 2px);
  margin-bottom: 5px;
}

input:focus-visible {
  outline: none;
}

.inputAlerts{
  display: flex;
  align-items:flex-start;
  color: #FF0400;
  font-size: 14PX;
  height: 30px;
  width: 100%;
  margin-bottom:20px;
}
  
.submit-btn {
  display: block;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  box-shadow: 2px 2px 7px #38d39f70;
  background: #5E916F;
  color: rgba(255, 255, 255, 0.8);
  margin: 20px 30px 0 30px;
  width: calc(100% - 60px);
  height: 35px;
  transition: all 1s;
}
  
.submit-btn:hover {
  color: rgb(255, 255, 255);
  box-shadow: none;
}

.inputContainer{
  position: relative;
  width: 100%;
  height: 90px;
}

.helpIcon{
  position: absolute;
  right: 15px;
  top: 25px;
  color: black;
  width: 20px;
  height: 20px;
}