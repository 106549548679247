.orderItemListContainer{    
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 620px;
  height: fit-content; 
  margin: 35px auto 0 auto;
  align-items: center; 
  justify-content: center;
  text-align: center;
  background: white;
  border: 1px solid #6c6c6c;
  border-radius: 12px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.199);
    
}