.productDetailsGrid {  
  position: relative;   
  display: grid;    
  margin: 0 auto;
  width: 100%;  
  grid-template-rows: auto 20px 495px;
  grid-template-columns: 1fr;
  grid-template-areas: "carrousel" "." "details";
}  

.detailsGrid{
  display: grid;
  margin: auto;
  width: 100%;  
  height: 465px;
  grid-template-rows: 45px 30px 60px 140px 120px 50px;
  grid-template-columns: 1fr;
  grid-template-areas: "title" "review" "price" "banner" "addToCartControls" "buyItNowButton";
  grid-area: details;
}

.ATCcontrolsGrid{
  display: grid;
  width: 100%;  
  height: 120px;
  grid-template-rows: 60px 60px;
  grid-template-columns: 33% 34% 33%;
  grid-template-areas: "lessQuantity quantity moreQuantity" "ATCbutton ATCbutton ATCbutton";
  grid-area: addToCartControls;
}

h2, h3, p {
  padding: 0;
  margin: 0; 
}

.carrusel {  
  border: 1px solid #C6B2F7;
  width: 100%;
  grid-area: carrousel; 
}

.carousel-item img {
  max-height: none;
} 

.lineProductDetails{
  display: none;
  margin-left: 20px;
  width: calc(100% - 44px);
  height: 1px;
  border-bottom: 1px solid #C6B2F7;
}
  
.titulo {  
  margin: auto 0;
  width: fit-content;
  height: 45px;
  font-size: 33px;
  font-weight: 400;  
  grid-area: title;
}

.reviewContainer{
  display:flex;
  position: relative;
  grid-area: review;
}

.reviewStars{
  display: flex;
  margin: auto 0;
  height: 20px;     
}

.reviewText{
  position: relative;
  top: 5px;
  left: 8px;  
  font-weight: 700;
}

.precio {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 700; 
  grid-area: price; 
}

.ecologyBanner {  
  position: relative;    
  margin: auto;
  grid-area: banner; 
} 

.lessQuantity{
  margin: auto 0;
  cursor: pointer;
  grid-area: lessQuantity;
}

.quantity{
  font-size: 30px;
  font-weight: 500;
  margin: auto;
}

.moreQuantity{
  margin: auto 0 auto auto;
  cursor: pointer;
  grid-area: moreQuantity;
}

.botonATC {
  margin: auto;
  background-color: #C6B2F7;  
  width: 100%;  
  height: 45px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  font-weight: 500;
  grid-area: ATCbutton;
}
  
.botonComprar {
  color: black;
  background-color: white;
  height: 45px;
  width: 100%;  
  margin: auto;
  border: 1px solid #C6B2F7;
  border-radius: 10px;  
  grid-area: buyItNowButton; 
}

.botonATC:hover {
  color: black;
}

.botonComprar:hover {
  background-color: white;
  color: black;
}

.descriptionContainer{
  position: relative;
  width: 100%;  
  margin: 20px auto 0 auto;
}
  
.tituloDescripcion {
  margin: 0 auto;
  font-size: 22px;
  font-weight: 500;
  grid-area: tituloDescripcion; 
}  

.descripcion{
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 15px;
  text-align: justify;
  grid-area: descripcion; 
}

@media screen and (min-width: 768px) {
  .productDetailsGrid {     
    display: grid;    
    margin: 20px auto 0 auto;
    width: 720px;
    height: 360px;    
    grid-template-columns: 360px 360px;
    grid-template-rows: 360px;
    grid-template-areas: "carrousel details";
  }  

  .detailsGrid{
    display: grid;
    margin: auto 0;
    width: 360px;
    height: 360px;
    grid-template-rows: 30px 40px 40px 110px 2px 70px 2px 66px;
    grid-template-columns: 360px;
    grid-template-areas: "title" "review" "price" "banner" "line1" "addToCartControls" "line2" "buyItNowButton";
    grid-area: details;
  }

  .ATCcontrolsGrid{
    display: grid;
    width: 360px;
    height: 70px;
    padding-left: 20px;
    grid-template-columns: 33px 40px 33px 234px;
    grid-template-rows: 70px;
    grid-template-areas: "lessQuantity quantity moreQuantity ATCbutton";
    grid-area: addToCartControls;
  }

  .carrusel img{
    max-height: 358px;
  }    
  
  .lineProductDetails{    
    display: flex;
    width: calc(100% - 24px);    
  }

  .titulo {   
    height: 30px;  
    font-size: 30px;   
  }

  .reviewStars{   
    padding: 0 10px 0 20px;   
  }  

  .reviewText{   
    left: 0px; 
    top: 10px;    
  }

  .ecologyBanner{
    margin-left: 0px;     
    height: 80px;
  }

  .ecologyBanner, .titulo, .precio{   
    padding: 0 20px;
  }

  .lessQuantity{
    width: 30px;
    height: 33px;
  }

  .moreQuantity{
    width: 30px;
    height: 33px;
  }

  .quantity{
    font-size: 25px;   
  }

  .botonATC {
    margin-right: 0;  
    width: 210px;
    height: 45px;    
  }

  .botonComprar { 
    margin-bottom: 0px;
    margin-right: 0;     
    height: 45px;
    width: calc(100% - 20px);    
  }
  
  .descriptionContainer{ 
    width: 720px;    
    margin: 40px auto 0 auto;
  }

  .tituloDescripcion{
    font-size: 23px;   
  }
  
  .descripcion{
    margin-top: 10px;
    font-size: 16px;    
  }
  
}
  
@media screen and (min-width: 1200px) {
  .productDetailsGrid {     
    display: grid;       
    width: 1000px;
    height: 500px;
    grid-template-columns: 500px 500px;
    grid-template-rows: 500px;
    grid-template-areas: "carrousel details";
  }  

  .detailsGrid{
    display: grid;
    margin: auto;
    width: 500px;
    height: 500px;
    grid-template-rows: 50px 40px 60px 166px 2px 100px 2px 80px;
    grid-template-columns: 500px;
    grid-template-areas: "title" "review" "price" "banner" "line1" "addToCartControls" "line2" "buyItNowButton";
    grid-area: details;
  }

  .ATCcontrolsGrid{
    display: grid;
    width: 500px;
    height: 100px;
    padding-left: 20px;
    grid-template-columns: 45px 50px 45px 340px;
    grid-template-rows: 100px;
    grid-template-areas: "lessQuantity quantity moreQuantity ATCbutton";
    grid-area: addToCartControls;
  }

  .carrusel img{
    max-height: 498px;
  }

  .lineProductDetails{    
    width: calc(100% - 44px);    
  }   

  .titulo {     
    height: 50px;
    font-size: 38px;   
  }    

  .ecologyBanner{ 
    bottom: 10px;   
    height: 110px;
  }  
  
  .lessQuantity{
    width: 37px;
    height: 40px;
  }

  .moreQuantity{
    width: 37px;
    height: 40px;
  }

  .quantity{
    font-size: 30px;   
  }

  .botonATC {  
    margin: auto;
    width: 294px;
    height: 56px;    
  }

  .botonComprar {  
    margin-bottom: 0px ; 
    margin-right: auto;
    height: 56px;
    width: calc(100% - 40px);    
  }

  .descriptionContainer{ 
    width: 1000px;     
  } 
}    