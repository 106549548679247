.redondeado {
    border-radius: 4px;
    box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.75); }

.fotosdeUso1, .fotosdeUso2, .fotosdeUso3, .fotosdeUso4, .fotosdeUso5, .fotosdeUso6, .fotosdeUso7, .fotosdeUso8{
    display: flex;
    width: 100%; }

.fotosdeUso1 {
    grid-area: fotosuso1; }

.fotosdeUso2 {
    grid-area: fotosuso2; }

.fotosdeUso3 {
    grid-area: fotosuso3; }

.fotosdeUso4 {
    grid-area: fotosuso4; }
    
.fotosdeUso5 {
    grid-area: fotosuso5; }
        
.fotosdeUso6 {
    grid-area: fotosuso6; }

.fotosdeUso7 {
    grid-area: fotosuso7;}
            
.fotosdeUso8 {
    grid-area: fotosuso8; }
