.itemHeader {
  display: grid;
  width: 100%;
  height: 50px;
  grid-template-columns: 140px 1fr 100px 40px 40px 40px 100px 120px;
  grid-template-rows: 50px;
  grid-template-areas: "productNameHeaderCart productNameHeaderCart priceHeaderCart quantityHeaderCart quantityHeaderCart quantityHeaderCart subTotalHeaderCart .";
  border: 1px solid black;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  font-size: 16px; 
  font-weight: 700;
  background-color: #F6F6F6;
}
  
.productNameHeaderCart {
  grid-area: productNameHeaderCart; } 
  
.priceHeaderCart {
  grid-area: priceHeaderCart; }
  
.quantityHeaderCart {
  grid-area: quantityHeaderCart; }
  
.subTotalHeaderCart {
  grid-area: subTotalHeaderCart; }